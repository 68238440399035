'use strict'

exports._esModule = true;
exports.default = {
    labelButtonCancel: "Cancel",
    labelButtonConfirm: "Done",
    labelButtonCropFlipHorizontal: "Flip horizontal",
    labelButtonCropFlipVertical: "Flip vertical",
    labelButtonCropRotateCenter: "",
    labelButtonCropRotateLeft: "Rotate left",
    labelButtonCropRotateRight: "Rotate right",
    labelButtonReset: "Reset",
    labelStatusAwaitingImage: "Waiting for image...",
    labelStatusLoadImageError: "Eror loading image...",
    labelStatusLoadingImage: "Loading image...",
    labelStatusProcessingImage: "Processing image..."
}