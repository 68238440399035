export default class FileUtils {
    static convertFromDataUrl(dataUrl) {
        var arr = dataUrl.split(',');
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);   
        
        while(n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        try {
            var file = new File([u8arr], `image.${mime.split('/')[1]}`, { type: mime });
            return file;
        } catch {
            var blob = new Blob([u8arr], { type: mime });
            blob.name = `image.${mime.split('/')[1]}`;
            return blob;
        }
    }
    static convertFromFile(file, name) {
        return new Blob([file], { type: file.type, name: name || file.name});
    }
}