<template>
    <file-pond 
    @error="showError"
    @addfile="addFile"
    :check-validity="true"
    :image-validate-size-min-height="imageMinHeight"
    :image-validate-size-min-width="imageMinWidth"
    :image-validate-size-label-image-size-too-small="$t('messages.image_too_small_main')"
    :image-validate-size-label-expected-min-size="$t('messages.image_too_small_sub')"
    :instant-upload="false"
    :label-max-file-size-exceeded="$t('messages.file_too_large_main')"
    :label-max-file-size="$t('messages.file_too_large_sub')"
    :max-file-size="maxFileSize"
    accepted-file-types="image/jpeg, image/png"
    ref="uploader"></file-pond>
</template>

<script>
import vueFilePond from 'vue-filepond';

import FilepondPluginFileValidateSize from  'filepond-plugin-file-validate-size'
import FilepondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilepondPluginImageValidateSize from 'filepond-plugin-image-validate-size';

const FilePond = vueFilePond(
    FilepondPluginFileValidateSize,
    FilepondPluginFileValidateType,
    FilepondPluginImageValidateSize
)

export default {
    components: {
        FilePond
    },
    methods: {
        addFile() {
            var fileItem = this.$refs.uploader.getFile();
            if(fileItem.status < 8) {
                this.$emit('upload', fileItem.file);
            } else {
                console.log('not shown', fileItem);
            }
        },
        open() {
            if(this.$refs.uploader) {
                this.$refs.uploader.removeFiles();
                this.$refs.uploader.browse();
            }
        },
        showError(err) {
            console.log(err);
            this.$confirm.warning(`<strong class="grey--text text--lighten-3 font-weight-bold text-h6">${err.main}</strong><br><br> ${err.sub}`, null, {
                allowHtml: true,
                backgroundColor: 'secondary',
                buttonTrueText: this.$t('confirm'),
                buttonFalseText: null,
                color: 'secondary',
                showIcon: false,
                showToolbar: false,
                theme: 'dark',
            });
        }
    },
    props: {
        maxFileSize: {
            type: String,
            required: true
        },
        imageMinWidth: {
            type: Number,
            default: 1
        },
        imageMinHeight: {
            type: Number,
            default: 1
        }
    },
}
</script>

<style>

</style>