export default class ImageDto {
    constructor() {
        this.content = null;
        this.contentType = null;
        this.extension = null;
    }

    static buildFromBase64(base64String) {
        var base64StringParts = base64String.split(',');
        var contentType = base64StringParts[0].split(';')[0].split(':')[1];
        var extension = `.${contentType.split('/')[1]}`;
        var instance = new ImageDto();
        instance.content = base64String;
        instance.contentType = contentType;
        instance.extension = extension;
        return instance;
    }
}