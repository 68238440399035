import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages_en from './lang/en';
import messages_de from './lang/de';

messages_de.doka = require('./lang/doka_de').default;
messages_en.doka = require('./lang/doka_en').default;


Vue.use(VueI18n);
export const i18n = new VueI18n({
    locale: process.env.VUE_APP_DEFAULT_LOCALE,
    fallbackLocale: process.VUE_APP_FALLBACK_LOCALE,
    messages: {
        en: messages_en,
        de: messages_de
    }
});