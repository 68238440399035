export default class MenuItem {
    constructor(label, link) {
        this.label = label;
        this.link = link;
        this.icon = null;
        this.needLogin = null;
    }
    static build(label, link) {
        return new MenuItem(label, link);
    }

    withIcon(icon) {
        this.icon = icon;
        return this;
    }

    withLogin() {
        this.needLogin = true;
        return this;
    }

    withoutLogin() {
        this.needLogin = false;
        return this;
    }
}