"use strict"

exports.__esModule = true;
exports.default = {
    "activate_new_card": "Neuen Ausweis aktivieren",
    "activated_since": "Aktiv seit",
    "back": "Zurück",
    "birthdate": "Geburtstag",
    "camera": "Kamera",
    "cancel": "Abbrechen",
    "card_activation": "Ausweis-Aktivierung",
    "card_activation_title": "Aktivierungscode eingeben",
    "card_number": "Ausweisnummer",
    "card_number_with_hint": "Ausweisnummer (bisheriger Ausweis)",
    "card_order": "Ausweisbestellung",
    "card_production": "Ausweisproduktion",
    "card_report_reason": {
        "info_problem": "Mein Name wird ungünstig dargestellt. Die Zeilenaufteilung muss überarbeitet werden.",
        "photo_problem": "Das Bild ist fehlerhaft freigestellt, eine Nachbearbeitung ist erforderlich."
    },
    "confirm": "Bestätigen",
    "confirmation_needed": "Bestätigung erforderlich",
    "delete": "Löschen",
    "edit_data": "Bearbeiten",
    "edit_picture": "Bildbearbeitung",
    "employee_id_card": "Mitarbeiterausweis",
    "firstname": "Vorname",
    "flip_vertival": "Vertikal",
    "hints": "Hinweis",
    "image_processing_hint": {
        "cut": "Das Foto ist am {directions} Rand abgeschnitten",
        "cutbot": "Das Foto ist am unteren Rand abgeschnitten",
        "cutleft": "Das Foto ist an der linken Seite abgeschnitten",
        "cutright": "Das Foto ist an der rechten Seite abgeschnitten",
        "cuttop": "Das Foto ist am oberen Rand abgeschnitten (Kopf)",
        "direction": {
            "bot": "unteren",
            "left": "linken",
            "right": "rechten",
            "top": "oberen"
        },
        "multiperson": "Auf dem Foto wurden mehrere Personen erkannt",
        "noperson": "Auf dem Foto wurde keine Person erkannt",
        "persontoobig": "Das Foto bietet zu wenig Platz für den Hintergrund",
        "toobright": "Das Foto ist zu hell",
        "toodark": "Das Foto ist zu dunkel",
        "toothin": "Das Foto ist zu schmal"
    },
    "lastname": "Nachname",
    "lets_go": "Los geht’s",
    "login": "Anmelden",
    "login_failed_title": "Die Anmeldedaten sind nicht korrekt",
    "logout": "Abmelden",
    "menu": {
        "cards": "Ausweise",
        "faq": "FAQ",
        "imprint": "Impressum",
        "privacy_policy": "Datenschutz"
    },
    "messages": {
        "add_card_picture": "Bitte fügen Sie per Upload oder Aufnahme ein Foto hinzu. Dieses wird für Ihren neuen Ausweis verwendet.",
        "bad_request": "Ungültige Anfrage",
        "cannot_reach_server": "Der Server kann nicht erreicht werden",
        "card_activation": "Bitte aktivieren Sie Ihren neuen Ausweis. Beachten Sie, dass es ca. 30 Min. dauert, bis dieser für Zutrittsbuchungen aktiv ist und die Freischaltung für die Zeiterfassung am Folgetag erfolgt. Ihr bisheriger Ausweis wird automatisch deaktiviert.",
        "card_activation_consent": "Möchten Sie die Aktivierung abschließen? Diese Aktion kann nicht rückgängig gemacht werden. Beachten Sie, dass es ca. 30 Min. dauert, bis dieser für Zutrittsbuchungen aktiv ist und die Freischaltung für die Zeiterfassung am Folgetag erfolgt. Behalten Sie Ihren bisherigen Ausweis, bis die Aktivierung vollständig abgeschlossen und der neue Ausweis funktionsfähig ist. Dieser wird im Rahmen der Aktivierung automatisch deaktiviert.",
        "card_activation_text": "Bitte aktivieren Sie Ihren Ausweis per Eingabe des Aktivierungscodes.",
        "card_creation_draft_approval": "Bitte prüfen Sie die Vorschau und bestellen Sie anschließend Ihren Ausweis.",
        "card_creation_production": "Ihr Ausweis wurde bestellt und wird nun produziert.",
        "consent_confirmation": "Hiermit bestätige ich die Richtigkeit und die Vollständigkeit meiner Angaben.",
        "consent_use_picture": "Hiermit erkläre ich mich einverstanden, dass mein Foto im Rahmen meiner digitalen Personalakte gespeichert und für administrative Zwecke verwendet wird.",
        "delete_picture_confirmation": "Wollen Sie Ihr Bild wirklich löschen?<br>Diese Aktion kann nicht rückgängig gemacht werden.",
        "file_too_large_main": "Dieses Foto ist zu groß",
        "file_too_large_sub": "Die Datei is zu groß. Bitte stellen Sie ein anderes Foto bereit (max. Dateigröße {filesize})",
        "image_processing_hints_failure_header": "Dieses Foto kann nicht für den Ausweis verwendet werden",
        "image_processing_hints_failure_message": "Bitte stellen Sie ein anderes Foto bereit oder passen Sie den Bildausschnitt entsprechend an.",
        "image_too_small_main": "Dieses Foto kann leider nicht für Ihren Ausweis verwendet werden",
        "image_too_small_sub": "Die Auflösung des Fotos ist zu gering. Bitte stellen Sie ein anderes Foto bereit (min. {minWidth} x {minHeight} Px).",
        "internal_server_error": "Hoppla, da ging etwas schief, wir werden versuchen, das zu beheben.",
        "login_failed_full_text": "Gründe hierfür können sein:<br><br>- Leerzeichen vor oder hinter der Eingabe<br>- Ausweisnummer eines anderen Ausweises (z.B. alte Ausweisnummer)<br>- Schreibweise bei Titel (im Feld Nachname): Mustermann Dr.<br><br>Bitte prüfen und korrigieren Sie die Angaben.",
        "login_failed_new_person_text": "Gründe hierfür können sein:<br><br>- Leerzeichen vor oder hinter der Eingabe<br>- Schreibweise bei Titel (im Feld Nachname): Mustermann Dr.<br><br>Bitte prüfen und korrigieren Sie die Angaben.",
        "logout_confirmation": "Möchten Sie sich wirklich ausloggen?",
        "new_card_activation": "Bitte aktivieren Sie Ihren neuen Ausweis. Beachten Sie, dass es ca. 30 Min. dauert, bis dieser für Zutrittsbuchungen aktiv ist und die Freischaltung für die Zeiterfassung am Folgetag erfolgt.",
        "new_card_activation_consent": "Möchten Sie die Aktivierung abschließen? Beachten Sie, dass es ca. 30 Min. dauert, bis Ihr neuer Ausweis für Zutrittsbuchungen aktiv ist. Die Freischaltung für die Zeiterfassung erfolgt am Folgetag. Diese Aktion kann nicht rückgängig gemacht werden.",
        "new_vesion_available": "Es ist eine neue Version verfügbar, bitte aktualisieren Sie diese Seite.",
        "not_found": "Nicht gefunden",
        "order_card_confirmation": "Möchten Sie Ihren neuen Ausweis bestellen? Diese Aktion kann nicht rückgängig gemacht werden.",
        "picture_quality_hint": "Bitte überprüfen Sie gegebenenfalls, ob das Foto den Anforderungen entspricht oder stellen Sie ein neues bereit.",
        "problem_with_camera_access": "Beim Zugriff auf die Kamera ist ein Problem aufgetreten.",
        "problem_with_camera_switching": "Beim Umschalten der Kamera ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
        "report_card": "Sie sind nicht zufrieden mit der Vorschau Ihres Ausweises?<br>Bitte geben Sie den Grund an.",
        "thanks_for_the_picture": "Vielen Dank. Das Foto wurde erfolgreich hinzugefügt. Bitte speichern Sie die Seite, um fortzufahren."
    },
    "next": "Weiter",
    "no": "Nein",
    "old_card_number": "bisheriger Ausweis",
    "order_card_now": "Ausweis jetzt bestellen",
    "order_new_card": "Neuen Ausweis bestellen",
    "person_data_and_picture": "Persönliche Daten und Foto",
    "personal_data": "Persönliche Daten",
    "personal_picture": "Persönliches Bild",
    "privacy_policy_login_text": "Hier finden Sie unsere Hinweise zum",
    "report_card_title": "Bemerkung zur Ausweisqualität",
    "report_text_placeholder": "Bemerkung hinzufügen",
    "rotate": "Drehen",
    "save": "Speichern",
    "titles": {
        "card_active": "Ausweis ist aktiv",
        "card_completed": "Ausweis produziert (inaktiv)",
        "card_draft": "Ausweis wird bearbeitet",
        "card_in_production": "Ausweis ist bestellt"
    },
    "update_available": "Update verfügbar",
    "validation": {
        "activation_code_is_not_valid": "Der Aktivierungscode ist ungültig"
    },
    "walkthrough": {
        "step_1": {
            "description": "Laden Sie ein Foto hoch und bestellen Sie Ihren {projectName} – ganz einfach und unkompliziert.",
            "title": "Herzlich willkommen"
        },
        "step_2": {
            "description": "Nehmen Sie ein Foto mit der Kamera auf oder wählen Sie eines aus Ihrer Galerie. Achten Sie darauf, dass das Foto hell und freundlich wirkt, dass Ihr Kopf nicht abgeschnitten ist und Sie vor einem neutralen Hintergrund zu sehen sind.",
            "title": "Sie wählen Ihr Foto selbst"
        },
        "step_3": {
            "description": "Beachten Sie bei der Bearbeitung des Fotos die Vorgaben. Positionieren Sie Ihren Kopf mittig und achten Sie darauf, dass ausreichend Platz für den Hintergrund vorhanden ist. Die Hilfslinien geben Ihnen Hinweis auf die perfekte Position.",
            "title": "Beachten Sie die Vorgaben"
        },
        "step_4": {
            "description": "Bestellen Sie Ihren Ausweis, sobald Sie mit der Vorschau zufrieden sind. Beachten Sie, dass der Ausweis genau so gedruckt wird, wie es auf der Vorschau zu sehen ist, es gibt keine weitere Bearbeitung. Falls Sie nicht zufrieden sind, stellen Sie einfach ein anderes Bild bereit.",
            "title": "Sehen Sie Ihren Ausweis in der Vorschau"
        },
        "step_5": {
            "description": "Viel Spaß beim Bestellen des Ausweises. Sie erhalten Ihren neuen {projectName} wenige Wochen nach der Bestellung per Hauspost. Weitere Informationen finden Sie in den FAQs der FotoApp.",
            "title": "Bestellen Sie jetzt!"
        }
    },
    "yes": "Ja"
}