import moment from 'moment';
import 'moment-timezone';

const DEFAULT_TIMEZONE = process.env.VUE_APP_TIMEZONE;
export default class DateTimeUtils {
    static formatDateTime(dateTime, format) {
        var momentDateTime = moment(dateTime);
        return momentDateTime.format(format);
    }
    static toLocalDateTime(dateTime) {
        return moment(dateTime).utc().tz(DEFAULT_TIMEZONE).toDate();
    }
}