import { i18n } from '@/localization/i18n';
export default class StepperTimelineViewModel {
    constructor() {
        this.state = null;
        this.title = null;
        this.icon = null;
        this.text = null;
    }
    static init(state) {
        var instance = new StepperTimelineViewModel();
        instance.state = state.toString();
        return instance;
    }
    withContent(title, text = null) {
        this.title = title;
        this.text = text;
        return this;
    }
    hasIcon(icon) {
        this.icon = icon;
        return this;
    }
    translate() {
        if(this.title) {
            this.title = i18n.t(this.title);
        }
        if(this.text) {
            this.text = i18n.t(this.text);
        }
        return this;
    }
}