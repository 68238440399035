<template>
    <v-dialog width="650"
              v-model="popupToggler">
        <v-card class="pa-6"
                color="secondary"
                dark>
            <v-card-title class="white--text text-h6">{{$t('report_card_title')}}</v-card-title>
            <v-card-text class="white--text">
                <p class="mt-4"
                   v-html="$t('messages.report_card')"></p>
                <v-form @submit.prevent="doReport"
                        class="container">
                    <v-row>
                        <v-col :key="index"
                               class="pa-0"
                               cols=12
                               v-for="(reason, index) in constantReasons">
                            <v-checkbox :label="$t(`card_report_reason.${reason}`)"
                                        :value="`${$t(`card_report_reason.${reason}`)}`"
                                        class="ma-0 pa-0"
                                        color="primary"
                                        v-model="report.reasons"></v-checkbox>
                        </v-col>
                        <v-col cols=12
                               class="pa-0">
                            <v-text-field :label="$t('report_text_placeholder')"
                                          light
                                          solo
                                          v-model="report.text"></v-text-field>
                        </v-col>
                        <v-col class="pt-0 text-right"
                               cols=12
                               md=9>
                            <v-btn :block="$vuetify.breakpoint.smAndDown"
                                   color="primary"
                                   type="submit">{{$t('confirm')}}</v-btn>
                        </v-col>
                        <v-col class="pt-0 text-right"
                               cols=12
                               md=3>
                            <v-btn @click="popupToggler = false"
                                   :block="$vuetify.breakpoint.smAndDown"
                                   text
                                   type="button">{{$t('cancel')}}</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import cardCreation from '@/api/cardCreation';
import CardReportReason from '@/application/Enums/CardReportReason';
import CardReportDto from '@/application/DataTableObjects/CardReportDto';
export default {
    computed: {
        constantReasons() {
            return CardReportReason;
        },
    },
    data() {
        return {
            popupToggler: false,
            report: new CardReportDto()
        }
    },
    methods: {
        doReport() {
            this.axios({
                url: cardCreation.report.url,
                method: cardCreation.report.method,
                data: this.report
            })
                .then(() => {
                    this.$emit('refresh', this.report);
                    this.$nextTick(() => {
                        this.popupToggler = false;
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },
        show() {
            this.popupToggler = true;
        }
    },
    mounted() {
        if (this.value) {
            this.report = CardReportDto.buildFromObject(this.value);
        }
    },
    name: 'report-card-modal',
    props: {
        value: {
            type: Object,
        }
    },
    watch: {
        popupToggler() {
            if (this.value) {
                this.report = CardReportDto.buildFromObject(this.value);
            } else {
                this.report = new CardReportDto();
            }
        },
        value() {
            if (this.value) {
                this.report = CardReportDto.buildFromObject(this.value);
            } else {
                this.report = new CardReportDto();
            }
        }
    }
}
</script>

<style>
</style>