<template>
    <v-container
        :class="item.backgroundColor"
        class="h-100">
        <v-container 
            class="d-flex align-center justify-center h-50 w-100">
            <v-icon style="font-size: 15em" v-if="item.icon">
                {{item.icon}}
            </v-icon>
            <img :src="item.image" class="w-auto" style='height: 250px;'>
        </v-container>
        <v-container class="h-50 text-center d-flex flex-column mt-3 mt-sm-8">
            <h2 class="text-h5">{{ item.title }}</h2>
            <span class="my-3"></span>
            <p>{{ item.text }}</p>
        </v-container>
    </v-container>
</template>

<script>
import WalkthroughItem from '../../application/ViewModels/WalkthroughItem'

export default {
    props: {
        item: WalkthroughItem
    }
}
</script>