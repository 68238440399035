<template>
    <v-timeline class="stepper-timeline pa-0" dense>
        <div class="line" :style="lineProgress"></div>
        <v-timeline-item
            :class="step.state === value ? 'active' : null"
            :color="step.state === value ? 'secondary' : nonActiveStepColor(step.state)"
            :icon="step.state === value ? step.icon : null"
            :key="index"
            :large="step.state === value"
            :left="itemsPosition === 'left'"
            :right="itemsPosition === 'right'"
            :small="step.state !== value"
            class="justify-center align-center"
            dark
            fill-dot
            v-for="(step, index) in steps">
            <span v-if="step.state !== value">{{step.title}}</span>
            <v-card v-if="step.state === value && step.text" color="secondary">
                <v-card-title class="subtitle-2">{{step.title}}</v-card-title>
                <v-card-text>{{step.text}}</v-card-text>
            </v-card>
        </v-timeline-item>
    </v-timeline>
</template>

<script>
export default {
    computed: {
        nonActiveStepColor() {
            return state => {
                var activeStateIndex = this.steps.findIndex(s => s.state === this.value);
                var stateIndex = this.steps.findIndex(s => s.state === state);
                if(stateIndex < activeStateIndex) return 'primary';
                return this.defaultColor;
            };
        }
    },
    data() {
        return {
            lineProgress: { top: 10 }
        }
    },
    methods: {
        async updateProgress() {
            var timelineRoot = document.getElementsByClassName('stepper-timeline')[0];
            var progressLine = timelineRoot?.getElementsByClassName('line')[0];
            var firstActiveItem = timelineRoot?.getElementsByClassName('active')[0];
            var dots = timelineRoot?.getElementsByClassName('v-timeline-item');
            var lineCssStyle = this.lineProgress;
            if(timelineRoot) {
                //let timelineStart = timelineRoot.offsetTop;
                let startPosition = progressLine.offsetTop;
                let activeItemPosition = firstActiveItem.offsetTop;
                let firstDotPosition = dots[0].offsetTop;
                let lastDotPosition = dots[dots.length - 1].offsetTop;
                let middlePosition = firstActiveItem.getElementsByClassName('v-timeline-item__body')[0].clientHeight / 2;
                let lineFilledHeight = activeItemPosition - startPosition + middlePosition;
                let lastDotCenterIncrementor = dots[dots.length - 1].getElementsByClassName('v-timeline-item__body')[0].clientHeight / 2;
                let lineMaxHeight = lastDotPosition - firstDotPosition + lastDotCenterIncrementor;
                lineCssStyle = {
                    'top': 0,
                    'max-height': lineMaxHeight + "px",
                    'background': "linear-gradient(to bottom, #37c391 0%, #37c391 " + lineFilledHeight + "px, white " + lineFilledHeight + "px, white 100%)",
                };
                this.lineProgress = lineCssStyle;
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.updateProgress();
            window.onresize = () => {
                this.updateProgress();
            }
        });
    },
    name: 'stepper-timeline',
    props: {
        defaultColor: {
            type: String,
            default: 'white'
        },
        itemsPosition: {
            type: String,
            default: 'right'
        },
        steps: {
            type: Array,
            default: () => []
        },
        value: {
            type: String,
            required: true
        },
    },
    watch: {
        value: {
            immediate: true,
            handler: 'updateProgress'
        }
    }
}
</script>

<style lang="scss">
.stepper-timeline {
    color: #000;
    &.v-timeline {
        &::before {
            display: none;
        }
        .line {
            position: absolute;
            top: 0;
            bottom: 0;
            right: auto;
            left: 46px;

            height:100%;
            width: 4px;
            border: 1px solid #e3e3e3;
            transition: all 2s ease;
            background: linear-gradient(to bottom, #37c391 0%, #37c391 0%, white 0%, white 100%);
        }
        .v-timeline-item {
            &:first-child {
                padding-top: 0px;
            }
            &:last-child {
                padding-bottom: 0px;
            }
        }
        .v-timeline-item--fill-dot {
            .v-timeline-item__inner-dot {
                box-shadow: 1px 5px 5px #cbcbcb;
            }
        }
    }
}
</style>