<template>
    <v-dialog
        width="650"
        v-model="popupToggler">
        <v-card class="pa-6" color="secondary" dark>
            <v-card-title class='text-h6 white--text' style="word-break: break-word;">{{$t('card_activation_title')}}</v-card-title>
            <v-card-text>
                <p class="my-5">{{$t('messages.card_activation_text')}}</p>
                <v-text-field 
                    label="Code eingeben" 
                    outlined 
                    solo 
                    light 
                    v-model="activationCode"></v-text-field>
                <v-checkbox color="primary" class="rich-checkbox mt-0 d-inline-block" v-model="activationConsent">
                    <template v-slot:label>
                        <p class="text-body-2 mb-0">{{ consent }}</p>
                    </template>
                </v-checkbox>
                <v-row class="mt-2">
                    <v-col class='pt-0 text-right' cols=12 md=9>
                        <v-btn 
                            @click="doActivateCard()" 
                            :block="$vuetify.breakpoint.smAndDown"
                            :disabled="!activationConsent || !activationCode || activationCode === ''"  
                            color="primary">{{$t('confirm')}}</v-btn>
                    </v-col>
                    <v-col class="pt-0 text-right" cols=12 md=3>
                        <v-btn 
                            @click="() => {popupToggler = false}" 
                            :block="$vuetify.breakpoint.smAndDown"
                            text>{{$t('cancel')}}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import cardCreation from '@/api/cardCreation';
export default {
    computed: {
        consent() {
            if(this.$store.state.onBoardingProcess) {
                return this.$t('messages.new_card_activation_consent');
            }
            return this.$t('messages.card_activation_consent') 
        }
    },
    data() {
        return {
            activationConsent: false,
            activationCode: null,
            popupToggler: false,
        }
    },
    methods: {
        doActivateCard() {
            this.axios({
                url: cardCreation.activate.url,
                method: cardCreation.activate.method,
                data: {
                activationCode: this.activationCode
                }
            })
            .then(() => {
                this.$emit('refresh');
            })
            .catch(error => {
                console.error(error);
            })
        },
        show() {
            this.popupToggler = true;
        }
    },
    name: 'activation-code-modal',
}
</script>

<style>

</style>