import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import { i18n } from '../localization/i18n';

import Master from '../views/layouts/Master.vue';

import Card from '../views/Card.vue';
import FAQ from '../views/FAQ.vue';
import Imprint from '../views/Imprint.vue';
import Login from '../views/Login.vue';
import Maintenance from '../views/Maintenance.vue';
import Privacy from '../views/Privacy.vue';
import Walkthrough from '../views/Walkthrough.vue';
import Vuetify from '../plugins/vuetify';
import AppUtils from '../application/Utils/AppUtils';

const SUPPORTED_LOCALES = process.env.VUE_APP_SUPPORTED_LOCALES.split(',');

function localeIsSupported(lang) {
  return SUPPORTED_LOCALES.includes(lang);
}
const routes = [];
if (AppUtils.underMaintenance()) {
  routes.push({
    path: '/*',
    name: 'maintenance',
    component: Maintenance
  });
}
routes.push(...[
  {
    path: '/',
    name: 'root',
    beforeEnter(to, form, next) {
      if (AppUtils.underMaintenance()) {
        next('/maintenance');
      } else {
        next(process.env.VUE_APP_DEFAULT_LOCALE + "/login")
      }
    }
  },
  {
    beforeEnter(to, from, next) {
      const lang = to.params.lang;
      if (!localeIsSupported(lang)) {
        return next(process.env.VUE_APP_DEFAULT_LOCALE + 'login');
      }
      if (i18n.locale !== lang) {
        i18n.locale = lang;
      }
      Vuetify.framework.lang.current = i18n.locale;
      return next();
    },
    path: '/:lang/login/:mode?',
    component: Login,
    name: 'login',
    props: true
  },
  {
    beforeEnter(to, from, next) {
      const lang = to.params.lang;
      if (!localeIsSupported(lang)) {
        return next(process.env.VUE_APP_DEFAULT_LOCALE + 'walkthrough');
      }
      if (i18n.locale !== lang) {
        i18n.locale = lang;
      }
      Vuetify.framework.lang.current = i18n.locale;
      return next();
    },
    name: 'walkthrough',
    path: '/:lang/walkthrough',
    component: Walkthrough
  },
  {
    beforeEnter(to, from, next) {
      const lang = to.params.lang;
      if (!localeIsSupported(lang)) {
        var toPath = to.path === '/' ? 'login' : to.path;
        return next(process.env.VUE_APP_DEFAULT_LOCALE + toPath);
      } else {
        if (to.path === `/${lang}`) {
          return next('login');
        }
      }
      if (i18n.locale !== lang) {
        i18n.locale = lang;
      }
      Vuetify.framework.lang.current = i18n.locale;
      return next();
    },
    path: '/:lang',
    component: Master,
    children: [
      {
        path: 'card',
        name: 'card',
        component: Card,
      },
      {
        path: 'faq',
        name: 'faq',
        component: FAQ,
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: Privacy
      },
      {
        path: 'imprint',
        name: 'imprint',
        component: Imprint,
      },
    ],
  },
]);

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
