<template>
    <v-container class="mw-lg py-12 px-6">
        <div :key="rowIndex"
             v-for="(segmentRow, rowIndex) in segments">
            <div :key="index"
                 v-for="(segment, index) in segmentRow">
                <h2 class="text-h5 secondary--text"
                    v-if="segment.header">
                    {{segment.header}}</h2>
                <div v-else>
                    <h3
                        class="text-h6 font-weight-bold secondary--text py-5">
                        {{segment.title}}
                    </h3>
                    <p v-html="segment.content"
                       v-if="typeof segment.content === 'string'">
                    </p>
                    <expander :items="segment.content"
                              class="mb-6"
                              v-else>
                    </expander>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import Expander from "@/components/general/Expander";
export default {
    components: {
        Expander,
    },
    data() {
        return {
            segments: [],
        };
    },
    mounted() {
        this.getJson("privacy.json")
            .then((response) => response.json())
            .then((data) => (this.segments = data));
    },
    name: "privacy-index",
    title() {
        return this.$t("menu.privacy_policy");
    },
};
</script>
