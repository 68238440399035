import ImageDto from './ImageDto';
import FileUtils from '../Utils/FileUtils';
export default class CardCreationDto {
    constructor() {
        this.imageProcessingHints = [];
        this.personImageOriginal = null;
        this.personImageProcessed = null;
        this.usePictureConsent = true;
    }

    static buildFromObject(obj) {
        var instance = new CardCreationDto();
        if (!obj) return instance;
        instance.imageProcessingHints = obj.imageProcessingHints;
        instance.personImageOriginal = obj.personImageOriginal;
        instance.personImageProcessed = obj.personImageProcessed;
        if (obj.usePictureConsent !== undefined) {
            instance.usePictureConsent = obj.usePictureConsent;
        }
        return instance;
    }
    setOriginalImageFromBase64(base64String) {
        this.personImageOriginal = ImageDto.buildFromBase64(base64String);
        return this;
    }
    setProcessedImageFromBase64(base64String) {
        this.personImageProcessed = ImageDto.buildFromBase64(base64String);
        return this;
    }

    setImagesAsBlob(key, image) {
        this[key] = image;
        return this;
    }

    getFormData() {
        var formData = new FormData();
        for (let i in this.imageProcessingHints) {
            formData.append("imageProcessingHints[]", this.imageProcessingHints[i]);
        }
        var personImageOriginal = null;
        if (typeof this.personImageOriginal === 'string') {
            personImageOriginal = FileUtils.convertFromDataUrl(this.personImageOriginal, "personOriginalImage.png");
        } else {
            personImageOriginal = FileUtils.convertFromFile(this.personImageOriginal);
        }
        var personImageProcessed = FileUtils.convertFromDataUrl(this.personImageProcessed.content, "personProcessedImage.png");
        formData.append("personImageOriginal", personImageOriginal);
        formData.append("personImageProcessed", personImageProcessed);
        if (this.usePictureConsent) {
            formData.append("usePictureConsent", this.usePictureConsent);
        }
        return formData;
    }
}