export class MessageViewModel {
    constructor(text, type) {
        this.text = text;
        this.type = type;
    }
    static error(text) {
        return new MessageViewModel(text, 'error');
    }
    static success(text) {
        return new MessageViewModel(text, 'success');
    }
    static warning(text) {
        return new MessageViewModel(text, 'warning');
    }
}