import 'babel-polyfill';
import 'filepond-polyfill';
import './plugins/vue-doka/polyfill/doka.polyfill.min.js';

import './api/axiosConfig';
import VueCroppie from 'vue-croppie';

import Vue from 'vue'
import { i18n } from './localization/i18n';
import router from './router'

import mixins from './mixins';

//import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import { VueMaskDirective } from 'v-mask';

Vue.directive('mask', VueMaskDirective);
Vue.mixin(mixins);
Vue.use(VueCroppie);

const vm = new Vue({
    router,
    i18n,
    store,
    vuetify,
    //render: h => h(App),
    beforeMount() {
        var loginData = this.$route.query.loginData;
        if (loginData) {
            window.location.href = `https://lbbw.secureoncloud.de/cards/#/de/login/new?loginData=${loginData}`;
        } else {
            window.location.href = "https://lbbw.secureoncloud.de/cards";
        }
        return;
    }
}).$mount('#app')

window.vue = vm;