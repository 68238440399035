import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        authenticated: false,
        loading: false,
        loginFormData: null,
        title: '',
        onBoardingProcess: false,
        userManager: null,
    },
    mutations: {
        setOnboardingProcess(state, value) {
            state.onBoardingProcess = value;
        },
        authenticate(state, loginFormData) {
            state.authenticated = true;
            state.loginFormData = loginFormData;
        },
        logout(state, vueInstance) {
            vueInstance.$confirm.info(vueInstance.$t('messages.logout_confirmation'), vueInstance.$t('logout'), {
                backgroundColor: 'secondary',
                color: 'secondary',
                theme: 'dark',
                buttonTrueText: vueInstance.$t('yes'),
                buttonFalseText: vueInstance.$t('no'),
                buttonFalseColor: 'white'
            })
                .then(() => {
                    state.accessToken = null;
                    state.authenticated = false;
                    state.loginFormData = null;
                    if (state.userManager) {
                        state.userManager.signoutRedirect();
                    } else {
                        vueInstance.$router.push({ name: 'login' });
                    }
                })
                .catch(() => {
                    console.log('canceled by the user');
                })
        },
        setLoading(state, value) {
            state.loading = value;
        },
        setTitle(state, value) {
            state.title = value;
        },
        setUserManager(state, value) {
            state.userManager = value;
        }
    },
    actions: {
    },
    modules: {
    }
})
