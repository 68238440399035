import EventBus from '../EventBus';

export default class GlobalMessageHelper {
    constructor(vue) {
        this.vue = vue;
        EventBus.$on("error", (message) => this.showError(message));
        EventBus.$on("success", (message) => this.showSuccess(message));
        EventBus.$on("warning", (message) => this.showWarning(message));
    }
    showError(message) {
        this.vue.$message.error(message.text);
    }
    showWarning(message) {
        this.vue.$message.warning(message.text);
    }
    showSuccess(message) {
        this.vue.$message.success(message.text);
    }
    static init(vue) {
        new GlobalMessageHelper(vue);
    }
}