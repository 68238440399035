<template>
    <v-card 
        :style="$vuetify.breakpoint.smAndDown ? 'height: calc(100vh - 38px)' : null"
        class="elevation-sm-12" 
        tile 
        color="secondary">
        <v-card-text class="pa-0">
            <v-btn 
                @click="closeForm" 
                class="hidden-sm-and-down"
                dark 
                icon 
                style="position: absolute; left: 2%; top: 2%;">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn 
                @click="closeForm"
                class="hidden-md-and-up"
                dark
                icon
                style="position: fixed; right: 4%; top: 5px; z-index:99">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-container class="pa-0 pa-sm-4">
                <v-container class="pa-0 mw-xs position-relative text-center" fluid>
                    <easy-vue-camera
                        @approve="doApprove"
                        @close="$emit('close')"
                        :auto-detect-mobile="false"
                        :overlay-mask="picture === null ? require('@/assets/images/square_grid.png') : null"
                        :fullscreen="$vuetify.breakpoint.smAndDown"
                        :fullscreen-z-index="5"
                        must-approve
                        output="blob"
                        ref="easyVueCamera"
                        v-model="picture"></easy-vue-camera>
                    <v-row class="my-4" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="4" md="5" class="d-flex align-center justify-end">
                            <v-btn @click="toggleMask" color="grey" fab small v-if="!picture"><v-icon color="white" small>mdi-grid-large</v-icon></v-btn>
                        </v-col>
                        <v-col cols="4" md="2" class="d-flex align-center justify-center">
                            <v-btn @click="takePicture" color="primary" fab v-if="picture === null"><v-icon>mdi-camera-outline</v-icon></v-btn>
                            <v-btn @click="doApprove()" color="primary" fab v-if="picture !== null"><v-icon>mdi-check</v-icon></v-btn>
                        </v-col>
                        <v-col cols="4" md="5" class="d-flex align-center justify-start">
                            <v-btn @click="retry" color="warning" small fab v-if="picture !== null"><v-icon>mdi-camera-retake-outline</v-icon></v-btn>
                            <v-btn @click="flipCamera" color="info" small fab v-if="multiCamera && picture === null"><v-icon>mdi-camera-front-variant</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-container>

            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import ScrollUtils from '@/application/Utils/ScrollUtils';
import EasyVueCamera from 'easy-vue-camera';
export default {
    components: {
        EasyVueCamera
    },
    computed: {
        multiCamera() {
            if(!this.$refs.easyVueCamera) return false;
            return this.$refs.easyVueCamera.multiDevice;
        }
    },
    data() {
        return {
            picture: null,
            visibleOverlay: false,
        }
    },
    methods: {
        closeForm() {
            this.$refs.easyVueCamera.close();    
        },
        doApprove(picture) {
            picture = picture || this.picture;
            this.$emit('input', picture);
            this.$emit('approve');
        },
        flipCamera() {
            this.$refs.easyVueCamera.switch();
        },
        retry() {
            this.picture = null;
            this.$refs.easyVueCamera.start();
        },
        takePicture() {
            this.$refs.easyVueCamera.snap();
        },
        toggleMask() {
            this.$refs.easyVueCamera.toggleMask();
        }
    },
    mounted() {
        this.$store.commit('setTitle', this.$t('camera'));
        this.$nextTick(() => {
            ScrollUtils.scrollTop();
        });
    },
    name: 'camera-control',
    props: {
        value: {}
    },
}
</script>
<style lang="scss" scoped>
.camera-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 49%;
    left:50%;
    transform: translate(-50%, -50%);
}
</style>