<template>
    <v-app
           :style="{background: $vuetify.theme.themes[theme].background}">
        <loader v-if="loading"></loader>
        <menu-bar @toggle-sidebar="toggleSidebar"
                  :title="pageTitleComputed">
        </menu-bar>
        <v-main>
            <side-bar class="hidden-sm-and-down"
                      drawer
                      permanent></side-bar>
            <side-bar class="hidden-md-and-up"
                      mobile
                      temporary
                      v-model="temporarySidebarToggler">
            </side-bar>
            <v-container class="pa-0 mt-0 mt-sm-7"
                         fluid>
                <router-view></router-view>
            </v-container>
        </v-main>
        <bottom-bar class="hidden-md-and-up">
        </bottom-bar>
    </v-app>
</template>

<script>
import Loader from "@/components/global/Loader";
import MenuBar from "@/components/global/MenuBar";
import SideBar from "@/components/global/SideBar";
import BottomBar from "@/components/global/BottomBar";
import GlobalMessageHelper from "@/application/Helpers/GlobalMessageHelper";
export default {
    components: {
        Loader,
        MenuBar,
        SideBar,
        BottomBar,
    },
    computed: {
        loading() {
            return this.$store.state.loading;
        },
        pageTitleComputed() {
            const title = this.$store.state.title;
            return title;
        },
        theme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        },
    },
    data() {
        return {
            pageTitle: "",
            temporarySidebarToggler: false,
        };
    },
    methods: {
        toggleSidebar() {
            this.temporarySidebarToggler = !this.temporarySidebarToggler;
        },
    },
    mounted() {
        GlobalMessageHelper.init(this);
    },
    name: "master",
};
</script>