<template>
    <v-bottom-navigation app color="primary">
        <v-btn to="card" v-if="authenticated">
            <v-icon>mdi-credit-card-multiple-outline</v-icon>
        </v-btn>
        <v-btn to="login" v-else>
            <v-icon>mdi-login</v-icon>
        </v-btn>
        <v-btn to="privacy" v-if="!authenticated">
            <v-icon>mdi-shield-account-outline</v-icon>
        </v-btn>
        <v-btn to="faq">
            <v-icon>mdi-help</v-icon>
        </v-btn>
        <v-btn @click="logout" v-if="authenticated">
            <v-icon>mdi-logout</v-icon>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
export default {
    computed: {
        authenticated() {
            return this.$store.state.authenticated;
        }
    },
    methods: {
        logout() {
            this.$store.commit('logout', this);
        }
    },
    name: "bottom-bar"
}
</script>

<style lang="scss" scoped>
.v-btn--active {
    &::before {
        display: none;
    }
    background-color: rgba(0,0,0,.05) !important;
}
</style>