<template>
    <v-container class="mw-xl pa-0 pa-sm-3">
        <v-card tile
                elevation="12">
            <v-card-text>
                <v-container fluid>
                    <div>
                        <v-btn class="pa-0 pa-sm-3"
                               @click="$emit('edit')"
                               v-if="model.editable && cardDraft"
                               text>
                            <v-icon color="primary">mdi-arrow-left</v-icon>
                            <span class="ml-2">{{$t('edit_data')}}</span>
                        </v-btn>
                    </div>
                    <v-row>
                        <v-col class="px-sm-12"
                               cols=12
                               md=6>
                            <img :src="model.cardImage"
                                 class="w-100 elevation-12">
                            <div class="d-flex align-center mt-5"
                                 v-if="cardDraft">
                                <v-badge bordered
                                         color="orange"
                                         overlap
                                         :value="hasReport">
                                    <v-btn @click="requestReportCardModal"
                                           color="secondary"
                                           fab>
                                        <v-icon>mdi-comment-alert-outline</v-icon>
                                    </v-btn>
                                </v-badge>
                                <span class="mx-1 mx-sm-4"></span>
                                <div class="text-center w-100">
                                    <v-btn @click="orderCard"
                                           class="my-4 w-100"
                                           color="primary"
                                           rounded>{{ $t('order_card_now') }}</v-btn>
                                </div>
                            </div>
                            <v-btn @click="activateCardConfirmation"
                                   class="my-4"
                                   color="primary"
                                   block
                                   rounded
                                   v-if="cardCompleted">{{ $t('activate_new_card') }}</v-btn>
                        </v-col>
                        <v-col cols=12
                               md=6
                               v-if="!cardActivated">
                            <h3 class="pl-5 secondary--text">{{timelineTitle}}</h3>
                            <stepper-timeline :key="stepperKey"
                                              :steps="cardCreationSteps"
                                              :value="processedState"
                                              class="my-4"></stepper-timeline>
                        </v-col>
                        <v-col cols=12
                               md=6
                               v-else>
                            <h3 class="secondary--text mb-5">{{$t('titles.card_active')}}</h3>
                            <span class="d-block">{{$t('card_number')}}: <strong>{{ model.newCardNumber }}</strong></span>
                            <span class="d-block">{{$t('activated_since')}}: <strong>{{ activatedOn }}</strong></span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
        <activation-code-modal @refresh="$emit('refresh')"
                               ref="activationCodeModalComponent"
                               v-if="cardCompleted"></activation-code-modal>
        <report-card-modal @refresh="setReport"
                           :value="model.report"
                           ref="reportCardModalComponent"
                           v-if="cardDraft"></report-card-modal>
    </v-container>
</template>

<script>
import ReportCardModal from './ReportCardModal';
import ActivationCodeModal from './ActivationCodeModal';
import ScrollUtils from '@/application/Utils/ScrollUtils';
import MaskUtils from '@/application/Utils/MaskUtils';
import cardCreation from '@/api/cardCreation';
import CardCreationViewModel from '@/application/ViewModels/CardCreationViewModel';
import StepperTimelineViewModel from '@/application/ViewModels/StepperTimelineViewModel';
import CardCreationState from '@/application/Enums/CardCreationState';
import StepperTimeline from '@/components/general/StepperTimeline';
import DateTimeUtils from '@/application/Utils/DateTimeUtils';

export default {
    components: {
        ActivationCodeModal,
        ReportCardModal,
        StepperTimeline,
    },
    computed: {
        activatedOn() {
            if (!this.cardActivated) return null;
            var localeDateTime = DateTimeUtils.toLocalDateTime(this.model.activatedOn);
            return DateTimeUtils.formatDateTime(localeDateTime, this.dateTimeFormat.dateFormat);
        },
        cardActivated() {
            return this.processedState == CardCreationState.ACTIVATED;
        },
        cardCompleted() {
            return this.processedState == CardCreationState.COMPLETED;
        },
        cardCreationSteps() {
            const steps = [
                StepperTimelineViewModel.init(CardCreationState.PERSONAL_DATA).withContent('personal_data').translate(),
                StepperTimelineViewModel.init(CardCreationState.PERSONAL_PICTURE).withContent('personal_picture').translate(),
                StepperTimelineViewModel.init(CardCreationState.DRAFT).withContent('card_order', 'messages.card_creation_draft_approval').hasIcon('mdi-account-outline').translate(),
                StepperTimelineViewModel.init(CardCreationState.IN_PRODUCTION).withContent('card_production', 'messages.card_creation_production').hasIcon('mdi-printer').translate(),
            ];
            var activationStep = null;
            if (!this.$store.state.loginFormData?.cardNumber) {
                activationStep = StepperTimelineViewModel.init(CardCreationState.COMPLETED).withContent('card_activation', 'messages.new_card_activation').hasIcon('mdi-account-outline').translate();
            } else {
                activationStep = StepperTimelineViewModel.init(CardCreationState.COMPLETED).withContent('card_activation', 'messages.card_activation').hasIcon('mdi-account-outline').translate();
            }
            steps.push(activationStep);
            return steps;
        },
        cardDraft() {
            return this.processedState == CardCreationState.DRAFT;
        },
        cardInProduction() {
            return this.processedState == CardCreationState.IN_PRODUCTION;
        },
        hasReport() {
            return this.model.report !== null && this.model.report.isAValidForm();
        },
        onlyDigitsMask() {
            return MaskUtils.createOnlyDigitsMask()
        },
        processedState() {
            if (this.model.state <= CardCreationState.DRAFT) {
                return CardCreationState.DRAFT.toString();
            }
            if (this.model.state > CardCreationState.DRAFT && this.model.state <= CardCreationState.IN_PRODUCTION) {
                return CardCreationState.IN_PRODUCTION.toString();
            }
            if (this.model.state > CardCreationState.IN_PRODUCTION && this.model.state <= CardCreationState.COMPLETED) {
                return CardCreationState.COMPLETED.toString();
            }
            return CardCreationState.ACTIVATED.toString();
        },
        timelineTitle() {
            if (this.cardDraft) {
                return this.$t('titles.card_draft');
            }
            if (this.cardInProduction) {
                return this.$t('titles.card_in_production');
            }
            if (this.cardCompleted) {
                return this.$t('titles.card_completed')
            }
            return null;
        }
    },
    data() {
        return {
            model: new CardCreationViewModel(),
            stepperKey: 0,
        }
    },
    methods: {
        activateCardConfirmation() {
            this.$refs.activationCodeModalComponent.show();
        },
        orderCard() {
            this.$confirm.info(this.$t('messages.order_card_confirmation'), this.$t('confirmation_needed'), {
                backgroundColor: 'secondary',
                color: 'secondary',
                theme: 'dark',
                buttonTrueText: this.$t('yes'),
                buttonFalseText: this.$t('no'),
                buttonFalseColor: 'white'
            })
                .then(() => {
                    this.axios({
                        url: cardCreation.order.url,
                        method: cardCreation.order.method,
                        data: {}
                    })
                        .then(() => {
                            this.model.state = CardCreationState.ORDERED;
                            this.$nextTick(() => {
                                this.stepperKey += 1;
                            });
                        })
                        .catch(error => {
                            console.error(error);
                        });
                })
        },
        requestReportCardModal() {
            this.$refs.reportCardModalComponent.show();
        },
        setReport(report) {
            this.model.report = report;
        }
    },
    mounted() {
        this.$store.commit('setTitle', this.$t('employee_id_card'));
        this.model = CardCreationViewModel.buildFromObject(this.card);
        ScrollUtils.scrollTop();
    },
    name: 'card-overview',
    props: {
        card: {
            type: Object,
            required: true
        }
    },
    watch: {
        card: {
            deep: true,
            handler() {
                this.model = CardCreationViewModel.buildFromObject(this.card);
            }
        }
    }
}
</script>

<style>
</style>