import { i18n } from "../../localization/i18n";
import moment from 'moment';

export class LoginDto {
    constructor() {
        this.birthdate = null;
        this.cardNumber = null;
        this.firstname = null;
        this.lastname = null;
    }

    clone() {
        var instance = new LoginDto();
        instance.birthdate = this.birthdate;
        instance.cardNumber = this.cardNumber;
        instance.firstname = this.firstname;
        instance.lastname = this.lastname;
        return instance;
    }

    standardizeBirthdate() {
        var standardDateString = '';
        if(i18n.locale === 'de') {
            let dateParts = this.birthdate.split('.');
            standardDateString = moment(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`).format('YYYY-MM-DD');
        } else if(i18n.locale === 'en') {
            let dateParts = this.birthdate.split('/');
            standardDateString = moment(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`).format('YYYY-MM-DD');
        }
        this.birthdate = standardDateString;
        return this;
    }

    isValid(mode) {
        var canLogin = this.birthdate !== null && this.birthdate !== '' 
            && this.firstname !== null && this.firstname !== '' 
            && this.lastname !== null && this.lastname !== '';
        if(!mode) {
            return canLogin && this.cardNumber !== null && this.cardNumber !== '' 
        }
        return canLogin;
    }
}