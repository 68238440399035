<template>
    <v-card @dblclick="(e) => { e.preventDefault(); }"
            :style="$vuetify.breakpoint.smAndDown ? 'height: calc(100vh - 160px)' : null"
            color="secondary"
            elevation="12"
            tile
            image-crop-aspect-ratio="1">
        <div
             class='d-flex align-center pa-sm-2'>
            <v-btn @click="$emit('close')"
                   class="ml-auto"
                   dark
                   icon
                   v-if="$vuetify.breakpoint.mdAndUp">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn @click="$emit('close')"
                   class="ml-auto"
                   dark
                   icon
                   style="position: fixed; right: 2%; top: 1%; z-index:20;"
                   v-else>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <doka :allow-button-confirm="false"
              :allow-button-cancel="false"
              :allow-button-reset="false"
              :crop.sync="crop"
              :crop-allow-rotate="false"
              :crop-allow-image-flip-vertical="false"
              :crop-allow-image-flip-horizontal="false"
              :crop-allow-image-turn-right="false"
              :crop-allow-image-turn-left="false"
              :crop-limit-to-image-bounds="false"
              :crop-mask="getCropMask"
              :src="src"
              :style="$vuetify.breakpoint.smAndDown ? 'height: calc(100% - 200px)' : 'height: 600px'"
              :utils="utils"
              crop-aspect-ratio="1"
              ref="appDoka"
              v-bind="$t('doka')"></doka>
        <v-card-actions
                        class="flex-column py-6">
            <v-row class="w-100">
                <v-col cols=12
                       md=3></v-col>
                <v-col class="text-center"
                       cols=12
                       md=6>
                    <div
                         class="d-flex align-center justify-center">
                        <v-btn @click="rotate"
                               dark
                               text>
                            <v-icon>mdi-phone-rotate-portrait</v-icon>
                            <span class="ml-1">{{$t('rotate')}}</span>
                        </v-btn>
                        <v-btn @click="flipHorizontal"
                               dark
                               text>
                            <v-icon>mdi-axis-z-rotate-clockwise</v-icon>
                            <span class="ml-1">{{$t('flip_vertival')}}</span>
                        </v-btn>
                    </div>
                    <div class="my-6"></div>
                    <div
                         class="d-flex justify-center align-center position-relative">
                        <v-btn @click="goBack"
                               color="warning"
                               fab
                               small
                               elevation="0"
                               style='position: absolute; left: 25%;'
                               v-if="backToCamera">
                            <v-icon>mdi-camera-retake-outline</v-icon>
                        </v-btn>
                        <v-btn @click="saveDoka"
                               color="primary"
                               fab
                               elevation="0">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols=12
                       md=6></v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import { Doka } from "@/plugins/vue-doka";

export default {
    components: {
        Doka,
    },
    data() {
        return {
            cropOptions: [
                {
                    label: "Square",
                    value: 1,
                },
            ],
            crop: {
                flip: {
                    horizontal: false,
                },
                rotation: 0,
                aspectRatio: 1,
            },
            flippedHorizontal: false,
            utils: ["crop"],
        };
    },
    methods: {
        rotate() {
            this.crop.rotation -= 1.5708;
            this.$refs.appDoka.setData({
                crop: this.crop,
            });
        },
        flipHorizontal() {
            this.crop.flip.horizontal = !this.crop.flip.horizontal;
            this.$refs.appDoka.setData({
                crop: this.crop,
            });
        },
        getCropMask(root, setInnerHtml) {
            var img = require("../../assets/images/square_grid.png");

            setInnerHtml(
                root,
                `<image xlink:href="${img}" x="0%" y="0%" width="100%" height="100%" />`
            );
        },
        goBack() {
            this.$emit("retry");
        },
        async saveDoka() {
            this.$store.commit("setLoading", true);
            var result = await this.$refs.appDoka.save();
            this.$store.commit("setLoading", false);
            this.$emit("input", result);
            this.$emit("approve");
        },
    },
    mounted() {
        this.$store.commit("setTitle", this.$t("edit_picture"));
    },
    name: "card-edit-picture-doka",
    props: {
        backToCamera: Boolean,
        src: {},
        value: {},
    },
};
</script>

<style lang="scss">
    .doka--root {
        background: #123250 !important;
        .doka--button-action-confirm {
            color: white !important;
            background-color: #37c391 !important;
        }
        canvas {
            background: #123250 !important;
        }
        .doka--menu {
            display: none;
        }
        .doka--toolbar {
            display: none;
        }
        .doka--crop-subject {
            margin: 1.5rem !important;
        }
    }
</style>