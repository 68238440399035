import { DateTimeFormat } from './localization/formatting';
import { Mask } from './localization/masks';
import { i18n } from './localization/i18n';

function getTitle(vm) {
    const { title } = vm.$options;
    if (title) {
        return typeof title === 'function' ? title.call(vm) : title;
    }
}

export default {
    computed: {
        baseAddress() {
            return process.env.VUE_APP_BASE_ADDRESS || '';
        },
        companyName() {
            return process.env.VUE_APP_COMPANY || 'gis';
        },
        dateMask() {
            return Mask[i18n.locale].date;
        },
        dateTimeFormat() {
            return DateTimeFormat[i18n.locale];
        },
        projectName() {
            return process.env.VUE_APP_PROJECT_NAME || "FotoApp";
        }
    },
    methods: {
        getJson(jsonFile) {
            const staticAddress = process.env.VUE_APP_STATIC_FILED_PUBLIC || `${this.baseAddress}/statics`;
            return fetch(`${staticAddress}/${jsonFile}`);
        }
    },
    created() {
        document.title = process.env.VUE_APP_NAME;
        const title = getTitle(this);
        if (title) {
            this.$store.commit('setTitle', title);
        }
    },
}