export default {
    options: {
        customProperties: true,
    },
    light: {
        primary: process.env.VUE_APP_PRIMARY_COLOR,
        secondary: process.env.VUE_APP_SECONDARY_COLOR,
        background: '#f0f0f0',
    },
    dark: {
        primary: '#6B6988',
    }
}