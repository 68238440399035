<template>
    <div>
        <h3 class="text-h6 font-weight-bold secondary--text py-5"
            v-if="header">
            {{header}}</h3>
        <v-expansion-panels class="custom-expander"
                            multiple>
            <v-expansion-panel :key="index"
                               v-for="(item, index) in localItems">
                <v-expansion-panel-header>
                    <template v-slot:actions>
                        <v-icon color="primary">
                        $expand
                    </v-icon>
                    </template>
                    <span><span v-if="countFrom">{{ `${countFrom + index}. ` }}</span>{{item.title}}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p v-html="item.content"
                       v-if="!isArrayContent(item.content)">
                    </p>
                    <p v-else>
                    <ul>
                        <li :key="index"
                            v-for="(text, index) in item.content"
                            v-html="text">
                        </li>
                    </ul>
                    </p>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
export default {
    computed: {
        isArrayContent() {
            return (content) => Array.isArray(content);
        },
    },
    data() {
        return {
            header: null,
            localItems: [],
        };
    },
    mounted() {
        this.items.forEach((x) => {
            this.localItems.push(x);
        });
        if (this.localItems.length > 0) {
            if (this.localItems[0].header) {
                this.header = this.localItems[0].header;
                this.localItems.splice(0, 1);
            }
        }
    },
    name: "expander",
    props: {
        countFrom: Number,
        items: {
            type: Array,
            required: true,
        },
    },
};
</script>
<style lang="scss">
    .custom-expander {
        .v-expansion-panel-header--active {
            color: #37c391;
            border-bottom: 1px solid #eee;
            min-height: 0px !important;
            margin-bottom: 20px;
        }
    }
</style>