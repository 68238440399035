<template>
    <v-navigation-drawer 
        :drawer="drawer"
        :mini-variant="mini"
        :permanent="permanent"
        app 
        color="white" 
        :id="componentId"
        :value="value">
        <v-list-item :key="index" :to="item.link" v-for="(item, index) in menuItems" link>
            <v-list-item-icon>
                <v-icon color="primary">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{item.label}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout" link v-if="authenticated">
            <v-list-item-icon>
                <v-icon color="primary">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{$t('logout')}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <template v-slot:append>
            <v-btn 
                @click.stop="() => { mini = !mini; }"
                block 
                class="pa-0" 
                text 
                tile
                v-if="!mobile">
                <v-icon color="primary" v-if="mini">mdi-chevron-right</v-icon>
                <v-icon color="primary" v-if="!mini">mdi-chevron-left</v-icon>
            </v-btn>
        </template>
    </v-navigation-drawer>
</template>

<script>
import menuItems from '../../application/Menu/MenuItems';
import {v4 as uuid4} from 'uuid';
export default {
    computed: {
        authenticated() {
            return this.$store.state.authenticated;
        },
        menuItems() { 
            const _menuItems = menuItems;
            const output = [];
            _menuItems.forEach(mi => {
                if(mi.needLogin === true && this.$store.state.authenticated) {
                    output.push(mi);
                } else if(mi.needLogin === false && !this.$store.state.authenticated) {
                    output.push(mi);
                } else if(mi.needLogin === null) {
                    output.push(mi);
                }
            });
            return output;
        },
    },
    data() {
        return {
            componentId: uuid4().replace(/-/g, ''),
            mini: true
        }
    },
    methods: {
        logout() {
            this.$store.commit('logout', this);
        }
    },
    mounted() {
        if(this.mobile) {
            this.mini = false;
        }
    },
    name: 'side-bar',
    props: {
        mobile: {
            type: Boolean,
            default: false
        },
        value: {
            type: Boolean,
            default: false
        },
        drawer: {
            type: Boolean,
            default: false
        },
        permanent: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
    padding-top: 58px;
    .v-list-item--active {
        color: unset !important;
        &:before {
            background-color: rgba(0, 0, 0, .5);
        }
    }
}
</style>