<template>
    <v-container class="mw-lg py-12 px-6">
        <h1 class="text-h5 secondary--text">
            Fragenkatalog Bluenet
            {{ projectName }}
        </h1>
        <div
             v-if="segments && segments.length > 0">
            <expander :count-from="1"
                      :items="segments[0]">
            </expander>
            <expander :count-from="segments[0].length + 1"
                      :items="segments[1]">
            </expander>
            <expander :count-from="(segments[0].length + segments[1].length) + 1"
                      :items="segments[2]">
            </expander>
            <expander :count-from="(segments[0].length + segments[1].length + segments[2].length) + 1"
                      :items="segments[3]">
            </expander>
        </div>
    </v-container>
</template>

<script>
import Expander from "../components/general/Expander.vue";
export default {
    components: {
        Expander,
    },
    data() {
        return {
            segments: [],
        };
    },
    mounted() {
        this.getJson("faq.json")
            .then((response) => response.json())
            .then((data) => (this.segments = data));
    },
    title() {
        return this.$t("menu.faq");
    },
};
</script>

<style>
</style>