import CardCreationDto from '../DataTableObjects/CardCreationDto';
import CardReportDto from '../DataTableObjects/CardReportDto';
export default class CardCreationViewModel {
    constructor() {
        this.activatedOn = null;
        this.cardImage = null;
        this.draftDto = null;
        this.editable = false;
        this.newCardNumber = null;
        this.notFound = false;
        this.report = null;
        this.state = null;
    }
    
    static buildFromObject(obj) {
        var instance = new CardCreationViewModel();
        instance.activatedOn = obj.activatedOn;
        instance.cardImage = obj.cardImage;
        instance.draftDto = CardCreationDto.buildFromObject(obj.draftDto);
        instance.editable = obj.editable;
        instance.newCardNumber = obj.newCardNumber;
        instance.notFound = obj.notFound;
        instance.report = CardReportDto.buildFromObject(obj.report);
        instance.state = obj.state;
        return instance;
    }
}