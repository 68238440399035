<template>
  <card-edit @save="getCardCreation" :card="card" v-if="editing"></card-edit>
  <card-overview @edit="editing=true" @refresh="getCardCreation()" :card="card" v-else></card-overview>
</template>

<script>
import cardCreation from '@/api/cardCreation';
import CardEdit from '@/components/card/CardEdit';
import CardOverview from '@/components/card/CardOverview';
import CardCreationViewModel from '@/application/ViewModels/CardCreationViewModel';

export default {
  components: {
    CardEdit,
    CardOverview
  },
  data() {
    return {
      card: null,
      editing: true,
    }
  },
  methods: {
    getCardCreation() {
      this.axios({
        url: cardCreation.get.url,
        method: cardCreation.get.method,
      })
      .then(response => {
        this.card = CardCreationViewModel.buildFromObject(response.data);
        this.editing = this.card.notFound;
      })
      .catch(error => {
        console.error(error);
      });
    }
  },
  mounted() {
    this.getCardCreation();
    this.$nextTick(() => {
      window.onbeforeunload = function() {
        return true;
      }
    });
  },
  name: 'card',
  title() {
    return this.$t('card')
  },
}
</script>