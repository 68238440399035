<template>
    <v-app class="walkthrough">
        <div
             class="d-flex align-center justify-center py-3 w-100">
            <img
                 :src="require(`../assets/images/logos/${companyName}_navy.png`)">
        </div>
        <v-container class="pa-0 pa-sm-12"
                     fluid
                     style="height: calc(100% - 140px)">
            <v-container
                         class="pa-0 mw-sm h-100 position-relative">
                <v-carousel :continuous="false"
                            :show-arrows="false"
                            class="h-100"
                            hide-delimiters
                            light
                            ref="wtCarousel"
                            v-model="model">
                    <v-carousel-item :key="index"
                                     class:base="h-100"
                                     v-for="(item, index) in items">
                        <walkthrough-item
                                          :item="item">
                        </walkthrough-item>
                    </v-carousel-item>
                </v-carousel>
            </v-container>
        </v-container>
        <div
             class="white d-flex flex-column w-100">
            <v-container class="mw-sm p-0">
                <div
                     class="w-100 d-flex align-center justify-center py-2">
                    <v-icon
                        @click="model = index"
                        :color="currentStep(index) ? 'primary' : 'secondary'"
                        :class="{ active: currentStep(index) }"
                        :key="index"
                        class="mx-1 icon-button"
                        v-for="(item, index) in items">mdi-circle</v-icon>
                </div>
                <div
                     class="w-100 d-flex align-center py-5">
                    <v-btn @click="onBackPressed"
                           color="white"
                           v-if="!firstStep">
                        {{ $t('back') }}
                    </v-btn>
                    <v-btn @click="onNextPressed"
                           class="ml-auto"
                           color="primary"
                           v-if="!lastStep">
                        {{ $t('next') }}
                    </v-btn>
                    <v-btn @click="onGotItemPressed"
                           class="ml-auto"
                           color="primary"
                           v-else>
                        {{ $t('lets_go') }}
                    </v-btn>
                </div>
            </v-container>
        </div>
    </v-app>
</template>

<script>
import items from "../application/Walkthrough/walkthroughItems";
import WalkthroughItem from "../components/walkthrough/WalkthroughItem";
export default {
    components: {
        WalkthroughItem,
    },
    computed: {
        currentStep() {
            return (step) => step === this.model;
        },
        items() {
            return items;
        },
        firstStep() {
            return this.model === 0;
        },
        lastStep() {
            return !this.items[this.model + 1];
        },
    },
    data() {
        return {
            model: 0,
        };
    },
    methods: {
        onBackPressed() {
            this.$refs.wtCarousel.prev();
        },
        onGotItemPressed() {
            this.$router.push({ name: "card" });
        },
        onNextPressed() {
            this.$refs.wtCarousel.next();
        },
    },
    name: "walkthrough-view",
};
</script>

<style lang="scss">
    .walkthrough {
        .v-carousel__item {
            height: 100% !important;
        }
        .icon-button {
            cursor: pointer;
            &.active {
                &::after {
                    opacity: 0.12;
                }
            }
        }
    }
</style>