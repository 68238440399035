<template>
    <div></div>
</template>

<script>
export default {
    title() {
        return this.$t('menu.imprint')
    }
}
</script>

<style>

</style>