import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default class {
    static createOnlyDigitsMask() {
        return createNumberMask({
            prefix: '',
            includeThousandsSeparator: false,
            allowNegative: false,
        })
    }
}