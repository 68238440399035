<template>
    <v-app>
        <div class="secondary d-flex align-center justify-center py-3"
             style="height: 64px">
            <img :src="require(`../assets/images/logos/${companyName}.png`)" />
        </div>
        <v-container class="mw-sm mt-0 mt-sm-7 pa-0 pa-sm-4">
            <v-card color="white"
                    elevation="12"
                    tile>
                <div class="secondary pl-12 pb-12">
                    <img class="w-100"
                         src="../assets/images/hand.png" />
                </div>
                <v-card-text class="px-8">
                    <v-form @submit.prevent="doLogin"
                            aria-autocomplete="off"
                            autocomplete="off">
                        <v-text-field :label="$t('card_number_with_hint')"
                                      name="loginCardNumber"
                                      type="number"
                                      v-model="dto.cardNumber"
                                      v-if="mode === null">
                        </v-text-field>
                        <v-text-field :label="$t('firstname')"
                                      name="loginFirstname"
                                      type="text"
                                      v-model="dto.firstname">
                        </v-text-field>
                        <v-text-field :label="$t('lastname')"
                                      name="login_lastname"
                                      type="text"
                                      v-model="dto.lastname">
                        </v-text-field>
                        <v-text-field :label="$t('birthdate')"
                                      :placeholder="dateMask.placeholder"
                                      name="loginBirthdate"
                                      type="tel"
                                      v-mask="dateMask.mask"
                                      v-model="dto.birthdate">
                        </v-text-field>
                        <div class="my-4">
                            <v-btn :disabled="!validForm || loading"
                                   block
                                   color="primary"
                                   type="submit">
                                <v-progress-circular class="mr-2"
                                                     indeterminate
                                                     size="16"
                                                     width="2"
                                                     v-if="loading">
                                </v-progress-circular>
                                <span>{{ $t("login") }}</span>
                            </v-btn>
                            <div class="pt-2">
                                {{ $t("privacy_policy_login_text") }}
                                <router-link :to="{ name: 'privacy' }">
                                    {{ $t("menu.privacy_policy") }}
                                </router-link>.
                            </div>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-container>
    </v-app>
</template>

<script>
import auth from "@/api/auth";
import MaskUtils from "@/application/Utils/MaskUtils";
import { LoginDto } from "@/application/DataTableObjects/LoginDto";
import LoginMode from "@/application/Enums/LoginMode";
import GlobalMessageHelper from "@/application/Helpers/GlobalMessageHelper";
import axiosInstance from "@/api/axiosConfig";
import { authUtils } from '../utils/AuthUtils';
export default {
    beforeMount() {
        window.onbeforeunload = () => {
            // Leave this empty so it doesn't show the prevent close prompt in login view
        };
    },
    computed: {
        loading() {
            return this.$store.state.loading;
        },
        onlyDigitsMask() {
            return MaskUtils.createOnlyDigitsMask();
        },
        validForm() {
            return this.dto.isValid(this.mode);
        },
    },
    data() {
        return {
            dto: new LoginDto(),
        };
    },
    methods: {
        doLogin() {
            var data = this.dto.clone();
            data = data.standardizeBirthdate();
            if (this.mode === LoginMode.new) {
                data.cardNumber = null;
            }
            this.axios({
                url: auth.login.url,
                method: auth.login.method,
                data: data,
            })
                .then((response) => {
                    if (response.data.successful) {
                        this.$store.commit("authenticate", this.dto);
                        //Do it in vuex
                        axiosInstance.defaults.headers[
                            "authorization"
                        ] = `Bearer ${response.data.accessToken}`;
                        if (this.mode === LoginMode.new) {
                            this.$store.commit("setOnboardingProcess", true);
                        }
                        if (response.data.firstLogin) {
                            this.$router.push({ name: "walkthrough" });
                        } else {
                            this.$router.push({ name: "card" });
                        }
                    } else {
                        const title = this.$t("login_failed_title");
                        var message = '';
                        if (this.mode === LoginMode.new)
                            message = this.$t("messages.login_failed_new_person_text");
                        else
                            message = this.$t("messages.login_failed_full_text");
                        this.$confirm.info(
                            `<h5 class="text-h5 white--text">${title}</h5><br><br>${message}`,
                            null,
                            {
                                showToolbar: false,
                                buttonTrueText: this.$t("ok"),
                                color: "secondary",
                                theme: "dark",
                                allowHtml: true,
                            }
                        );
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        tryAutoLogin() {
            //When mode is null it means there is no cardNumber in the logindata so the login will not be successful
            if (this.mode === null) return;
            const loginData = this.$route.query.loginData;
            if (loginData) {
                try {
                    var loginString = decodeURIComponent(escape(window.atob(loginData)));
                    var loginParts = loginString.split("|");
                    this.dto.firstname = loginParts[0];
                    this.dto.lastname = loginParts[1];
                    this.dto.birthdate = loginParts[2];
                    this.$nextTick(() => {
                        this.doLogin();
                    });
                } catch {
                    console.warn("[app] - could not decode the login data");
                }
            }
        },
    },
    mounted() {
        GlobalMessageHelper.init(this);
        authUtils.getUser().then((user) => {
            if (user) {
                this.$store.commit('setUserManager', authUtils.userManager);
                this.dto = new LoginDto();
                //Commenting this out, removes the fields in CardEdit overview.
                // this.dto.firstname = user.profile.given_name;
                // this.dto.lastname = user.profile.family_name;
                this.$store.commit("authenticate", this.dto);
                axiosInstance.defaults.headers["authorization"] = `Bearer ${user.access_token}`;
                this.$nextTick(() => {
                    this.$router.push({ name: "card" });
                });
            } else {
                this.tryAutoLogin();
            }
        })
            .catch(() => this.tryAutoLogin())
    },
    name: "login",
    props: {
        mode: {
            type: String,
            default: null,
        },
    },
    title() {
        return this.$t("login");
    },
};
</script>
<style lang="scss">
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
</style>