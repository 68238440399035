// eslint-disable-next-line no-unused-vars
import { User, UserManager, WebStorageStateStore } from "oidc-client";

export default class AuthUtils {
    constructor() {
        const settings = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: process.env.VUE_APP_IDENTITY_SERVER_URL,
            client_id: process.env.VUE_APP_IDENTITY_SERVER_CLIENT_ID,
            automaticSilentRenew: true,
            response_type: "id_token token",
            scope: "openid profile microservice_api roles",
            redirect_uri: `${this.originFullPathUrl}/callback.html`,
            silent_redirect_uri: `${this.originFullPathUrl}/silent-renew.html`,
            post_logout_redirect_uri: this.originFullPathUrl,
            filterProtocolClaims: true,
            loadUserInfo: true,
        };
        this.userManager = new UserManager(settings);
    }
    get originFullPathUrl() {
        const originMidPath = process.env.VUE_APP_ORIGIN_MID_PATH;
        if (originMidPath) {
            return `${window.location.origin}/${originMidPath}`;
        }
        return window.location.origin;
    }

    /**
     * @returns { Promise<User> }
     */
    getUser() {
        return new Promise((resolve, reject) => {
            this.userManager
                .getUser()
                .then(user => {
                    if (user) {
                        resolve(user);
                        return;
                    }
                    this.userManager
                        .signinSilent()
                        .then(user => resolve(user))
                        .catch(() => reject(null));
                })
                .catch(() => {
                    this.userManager
                        .signinRedirect()
                        .then(user => resolve(user))
                        .catch(() => reject(null));
                });
        });
    }
}

const authUtils = new AuthUtils();
export { authUtils }