<template>
    <div>
        <h1 class="text-h6 white--text">{{ $t('messages.image_processing_hints_failure_header') }}</h1>
        <div class="w-100 text-center">
            <img :src="image" class='w-75'/>
        </div>
        
        <div class="pt-3">
            <strong>{{$t('hints')}}:</strong>
            <div :key="index" v-for="(hint, index) in mergedHints">
                - {{hint}}
            </div>
        </div>
        <p class='pt-4'>{{ $t('messages.image_processing_hints_failure_message') }}</p>
    </div>
</template>

<script>
import { i18n } from '../../localization/i18n';
export default {
    i18n,
    computed: {
        
        cutHints() {
            return this.hints.filter(x => x.indexOf('cut') >= 0);
        },
        mergedHints() {
            var mergedHints = this.hints.filter(x => x.indexOf('cut') === -1);
            var translations = [];
            if(this.cutHints.length > 0) {
                var directions = this.cutHints.map(x => this.$t(`image_processing_hint.direction.${x.replace('cut', '')}`));
                translations.push(this.$t('image_processing_hint.cut', { directions: directions.join(', ') }));
            }
            mergedHints.forEach(x => {
                translations.push(this.$t(`image_processing_hint.${x}`));
            });
            return translations;
        }
    },
    name: 'forbidden-hint-message',
    props: {
        image: {
            type: String,
            required: true
        },
        hints: {
            type: Array,
            required: true
        }
    }
}
</script>