export default class WalkthroughItem {
    constructor() {
        this.image = null;
        this.backgroundColor = null;
        this.forgroundColor = null;
        this.icon = null;
        this.text = null;
        this.title = null;
    }
    static withIcon(icon) {
        const instance = new WalkthroughItem();
        instance.icon = icon;
        return instance;
    }
    static withImage(image) {
        const instance = new WalkthroughItem();
        instance.image = require(`@/assets/images/walkthrough/${image}`);
        return instance;
    }

    setBackgroundColor(color) {
        this.backgroundColor = color;
        return this;
    }

    setForegroundColor(color) {
        this.forgroundColor = color;
        return this;
    }

    withText(text) {
        this.text = text;
        return this;
    }

    withTitle(title) {
        this.title = title;
        return this;
    }
}