export default class CardReportDto {
    constructor() {
        this.reasons = [];
        this.text = null;
    }
    isAValidForm() {
        return (this.reasons !== null && this.reasons.length > 0) ||
        this.text !== null && this.text !== '';
    }
    static buildFromObject(obj) {
        if(!obj) return null;
        let instance = new CardReportDto();
        instance.reasons = obj.reasons;
        instance.text = obj.text;
        return instance;
    }
}