export const DateTimeFormat = {
    en: {
        dateFormat: 'MM/DD/YYYY',
        timeFormat: 'HH:mm:ss',
        timeShortFormat: 'HH:mm',
        dateTimeShortFormat: 'MM/DD/YYYY HH:mm'
    },
    de: {
        dateFormat: 'DD.MM.YYYY',
        timeFormat: 'HH:mm:ss',
        timeShortFormat: 'HH:mm',
        dateTimeShortFormat: 'DD.MM.YYYY HH:mm'
    }
}