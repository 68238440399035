import Walkthrough from '../ViewModels/WalkthroughItem';
import { i18n } from '../../localization/i18n';
const projectName = process.env.VUE_APP_PROJECT_NAME || 'neuen Ausweis';
const items = [
    Walkthrough.withImage('step_welcome.png')
        .withTitle(i18n.t("walkthrough.step_1.title"))
        .withText(i18n.t("walkthrough.step_1.description", { projectName: projectName })),
    Walkthrough.withImage('step_take_picture.png')
        .withTitle(i18n.t("walkthrough.step_2.title"))
        .withText(i18n.t("walkthrough.step_2.description")),
    Walkthrough.withImage('step_crop_picture.png')
        .withTitle(i18n.t("walkthrough.step_3.title"))
        .withText(i18n.t("walkthrough.step_3.description")),
    Walkthrough.withImage('step_approval.png')
        .withTitle(i18n.t("walkthrough.step_4.title"))
        .withText(i18n.t("walkthrough.step_4.description")),
    Walkthrough.withImage('step_order.png')
        .withTitle(i18n.t("walkthrough.step_5.title"))
        .withText(i18n.t("walkthrough.step_5.description", { projectName: projectName })),
];

export default items;