export default {
    PERSONAL_DATA: -2,
    PERSONAL_PICTURE: -1,
    DRAFT: 1,
    ORDERED: 2,
    APPROVED: 3,
    IN_PRODUCTION: 4,
    PRODUCED: 5,
    COMPLETED: 6,
    ACTIVATED: 7
}