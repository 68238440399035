<template>
    <v-container class="mw-lm pa-0 pa-sm-3 relative">
        <camera-control @approve="cameraPictureApproved"
                        @close="closeCamera"
                        v-if="showCamera"
                        v-model="originalImage">
        </camera-control>
        <card-edit-picture-doka @approve="doProcessImage"
                                @close="showCardEditPicture=false"
                                @retry="() => { showCardEditPicture = false; showCamera = true; }"
                                :back-to-camera="editCanGoBackToCamera"
                                :src="originalImage"
                                v-if="showCardEditPicture"
                                v-model="processedImage">
        </card-edit-picture-doka>
        <v-card tile
                elevation="12"
                v-if="!showCamera && !showCardEditPicture">
            <v-card-text>
                <v-container fluid>
                    <v-container class="py-0">
                        <v-container class="pa-0 mw-xxxs text-center">
                            <img :src="picture"
                                 class="w-100" />
                        </v-container>
                        <v-container class="pa-0 mw-xxs text-center">
                            <div v-if="hasHints">
                                <v-row>
                                    <v-col cols=12
                                           class="text-center pb-0">
                                        <strong>{{$t('hints')}}:</strong>
                                    </v-col>
                                    <v-col class="text-center pb-0 text-subtitle-2">
                                        <div :key="index"
                                             v-for="(hint, index) in mergedProcessingHints">
                                            {{ hint }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-container>
                        <span class="w-100 d-block text-center text-caption font-weight-bold mt-4"
                              v-if="hasHints">{{$t('messages.picture_quality_hint')}}</span>
                        <div class="d-flex align-center justify-center my-4">
                            <v-btn @click="openCamera"
                                   :disabled="!isCameraSupported"
                                   fab
                                   color="primary"
                                   outlined>
                                <v-icon>mdi-camera-outline</v-icon>
                            </v-btn>
                            <span class="mx-1"></span>
                            <image-uploader @upload="upload"
                                            :image-min-height="300"
                                            :image-min-width="300"
                                            class='d-none'
                                            max-file-size="10MB"
                                            ref="imageUploader">
                            </image-uploader>
                            <v-btn @click="$refs.imageUploader.open()"
                                   fab
                                   color="primary"
                                   outlined>
                                <v-icon>mdi-attachment</v-icon>
                            </v-btn>
                            <div v-if="processedImage">
                                <span class="mx-1"></span>
                                <v-btn @click="editPicture"
                                       color="primary"
                                       elevation="2"
                                       fab>
                                    <v-icon>mdi-image-edit-outline</v-icon>
                                </v-btn>
                                <span class="mx-1"></span>
                                <v-btn @click="deletePicture"
                                       color="error"
                                       elevation="2"
                                       fab>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div class="d-flex align-center py-2"
                             v-if="showHint">
                            <v-avatar class="hidden-sm-and-down"
                                      color="secondary">
                                <v-icon large class="white--text">mdi-account-outline</v-icon>
                            </v-avatar>
                            <v-card class="cloud-card hidden-sm-and-down ml-4"
                                    color="secondary"
                                    elevation="4">
                                <v-card-text class="white--text">
                                    {{pictureHint}}
                                </v-card-text>
                            </v-card>
                            <v-card class="hidden-md-and-up"
                                    color="secondary"
                                    elevation="4">
                                <v-card-text class="white--text">
                                    {{pictureHint}}
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-container>
                    <v-container>
                        <v-row>
                            <v-col cols=12
                                   md=6
                                   v-if="userInfo.firstname">
                                <v-text-field :label="$t('firstname')"
                                              :value="userInfo.firstname"
                                              dense
                                              filled
                                              outlined
                                              readonly>
                                </v-text-field>
                            </v-col>
                            <v-col cols=12
                                   md=6
                                   v-if="userInfo.lastname">
                                <v-text-field :label="$t('lastname')"
                                              :value="userInfo.lastname"
                                              dense
                                              filled
                                              outlined
                                              readonly>
                                </v-text-field>
                            </v-col>
                            <v-col cols=12
                                   md=6
                                   v-if='userInfo.cardNumber'>
                                <v-text-field :label="$t('card_number')"
                                              :value="userInfo.cardNumber"
                                              dense
                                              filled
                                              outlined
                                              readonly>
                                </v-text-field>
                            </v-col>
                            <v-col cols=12
                                   md=6
                                   v-if="userInfo.birthdate">
                                <v-text-field :label="$t('birthdate')"
                                              :value="userInfo.birthdate"
                                              dense
                                              filled
                                              outlined
                                              readonly>
                                </v-text-field>
                            </v-col>
                            <v-col class="d-flex flex-column"
                                   cols=12>
                                <v-checkbox :label="$t('messages.consent_confirmation')"
                                            class="mt-0 d-inline-block"
                                            v-model="consentConfirmation">
                                </v-checkbox>
                                <!-- <v-checkbox :label="$t('messages.consent_use_picture')"
                                            class="mt-0"
                                            v-model="draftDto.usePictureConsent">
                                </v-checkbox> -->
                            </v-col>
                            <v-col class="ml-auto"
                                   cols=12
                                   md=6>
                                <v-btn @click="doSave"
                                       :disabled="!savable"
                                       block
                                       color="primary">
                                    {{$t('save')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-container>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import Vue from "vue";
import ScrollUtils from "@/application/Utils/ScrollUtils";
import images from "@/api/images";
import Camera from "easy-js-camera";
import cardCreation from "@/api/cardCreation";
import CameraControl from "./CameraControl";
import CardEditPictureDoka from "./CardEditPictureDoka";
import CardCreationDto from "@/application/DataTableObjects/CardCreationDto";
import ImageUploader from "../general/ImageUploader";
import { LoginDto } from "@/application/DataTableObjects/LoginDto";
import ForbiddenHintMessage from "../../components/card/ForbiddenHintMessage";
export default {
    beforeMount() {
        if (this.$store.state.loginFormData) {
            this.userInfo = this.$store.state.loginFormData;
        }
        if (this.card && this.card.draftDto) {
            this.draftDto = CardCreationDto.buildFromObject(this.card.draftDto);
            if (this.draftDto.personImageOriginal) {
                this.originalImage = this.draftDto.personImageOriginal.content;
            }
            if (this.draftDto.personImageProcessed) {
                this.processedImage = this.draftDto.personImageProcessed.content;
            }
            if (this.draftDto.imageProcessingHints) {
                this.imageProcessingHints = this.draftDto.imageProcessingHints;
            }
        }
    },
    components: {
        CameraControl,
        CardEditPictureDoka,
        ImageUploader,
    },
    computed: {
        hasHints() {
            return (
                this.imageProcessingHints &&
                this.imageProcessingHints.length > 0
            );
        },
        isCameraSupported() {
            return Camera.isCameraSupported();
        },
        forbiddenHints() {
            const forbiddenHints = process.env.VUE_APP_FORBIDDEN_IMAGE_PROCESSING_HINTS.split(
                ","
            );
            var filteredHints = this.imageProcessingHints.filter((x) => {
                return forbiddenHints.indexOf(x) !== -1;
            });
            return filteredHints;
        },
        picture() {
            if (this.processedImage) {
                return this.processedImage;
            }
            return require("../../assets/images/square_profile_image_placeholder.png");
        },
        pictureHint() {
            if (this.originalImage && this.processedImage) {
                return this.$t("messages.thanks_for_the_picture");
            } else {
                return this.$t("messages.add_card_picture");
            }
        },
        savable() {
            return (
                this.processedImage !== null &&
                this.consentConfirmation &&
                this.forbiddenHints.length === 0
            );
        },
        showHint() {
            return !this.draftDto.personImageOriginal;
        },
        cutHints() {
            if (
                !this.imageProcessingHints ||
                this.imageProcessingHints.length === 0
            )
                return [];
            return this.imageProcessingHints.filter(
                (x) => x.indexOf("cut") >= 0
            );
        },
        mergedProcessingHints() {
            if (
                !this.imageProcessingHints ||
                this.imageProcessingHints.length === 0
            )
                return [];
            var mergedHints = this.imageProcessingHints.filter(
                (x) => x.indexOf("cut") === -1
            );
            var translations = [];
            if (this.cutHints.length > 0) {
                var directions = this.cutHints.map((x) =>
                    this.$t(
                        `image_processing_hint.direction.${x.replace(
                            "cut",
                            ""
                        )}`
                    )
                );
                translations.push(
                    this.$t("image_processing_hint.cut", {
                        directions: directions.join(", "),
                    })
                );
            }
            mergedHints.forEach((x) => {
                translations.push(this.$t(`image_processing_hint.${x}`));
            });
            return translations;
        },
    },
    data() {
        return {
            userInfo: new LoginDto(),
            draftDto: new CardCreationDto(),
            consentConfirmation: false,
            editCanGoBackToCamera: false,
            showCamera: false,
            showCardEditPicture: false,
            originalImage: null,
            processedImage: null,
            imageProcessingHints: [],
        };
    },
    methods: {
        cameraPictureApproved() {
            this.showCamera = false;
            this.showCardEditPicture = true;
            this.editCanGoBackToCamera = true;
        },
        closeCamera() {
            this.showCamera = false;
            this.showCardEditPicture = false;
        },
        deletePicture() {
            this.$confirm
                .info(
                    this.$t("messages.delete_picture_confirmation"),
                    this.$t("delete"),
                    {
                        backgroundColor: "secondary",
                        theme: "dark",
                        color: "secondary",
                        allowHtml: true,
                        buttonTrueText: this.$t("yes"),
                        buttonFalseText: this.$t("no"),
                        buttonFalseColor: "white",
                    }
                )
                .then(() => {
                    this.processedImage = null;
                    this.originalImage = null;
                    this.draftDto.personImageProcessed = null;
                    this.draftDto.personImageOriginal = null;
                    this.imageProcessingHints = [];
                    this.draftDto.imageProcessingHints = [];
                });
        },
        doProcessImage() {
            if (!this.processedImage) return;
            var image = this.processedImage.file;
            var data = new FormData();
            data.append("image", image);
            this.axios({
                headers: {
                    "content-type": "multipart/form-data",
                },
                url: images.process.url,
                method: images.process.method,
                data: data,
            })
                .then((response) => {
                    this.processedImage = response.data.content;
                    this.imageProcessingHints = response.data.hintStrings;
                    this.draftDto.personImageOriginal = null;
                    this.draftDto.personImageProcessed = null;
                    this.$nextTick(() => {
                        if (this.forbiddenHints.length > 0) {
                            var message = this.renderForbiddenHintsMessage();
                            this.$confirm.warning(message.innerHTML, null, {
                                allowHtml: true,
                                backgroundColor: "secondary",
                                buttonTrueText: this.$t("confirm"),
                                buttonFalseText: null,
                                color: "secondary",
                                showIcon: false,
                                showToolbar: false,
                                theme: "dark",
                            });
                        }
                    });
                })
                .catch((error) => {
                    if (this.draftDto.personImageOriginal !== null) {
                        this.originalImage = this.draftDto.personImageOriginal.content;
                    } else {
                        this.originalImage = null;
                    }
                    if (this.draftDto.personImageProcessed !== null) {
                        this.processedImage = this.draftDto.personImageProcessed.content;
                    } else {
                        this.processedImage = null;
                    }
                    console.error(error);
                })
                .finally(() => {
                    this.showCardEditPicture = false;
                });
        },
        doSave() {
            this.draftDto.personImageOriginal = this.originalImage;
            this.draftDto.setProcessedImageFromBase64(this.processedImage);
            this.draftDto.imageProcessingHints =
                this.imageProcessingHints || [];
            var formData = this.draftDto.getFormData();
            this.axios({
                headers: {
                    "content-type": "multipart/form-data",
                },
                url: cardCreation.draft.url,
                method: cardCreation.draft.method,
                data: formData,
            })
                .then(() => {
                    this.$emit("save");
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        editPicture() {
            this.editCanGoBackToCamera = false;
            this.$nextTick(() => {
                this.showCardEditPicture = true;
            });
        },
        openCamera() {
            this.showCamera = true;
        },
        renderForbiddenHintsMessage() {
            const Ctor = Vue.extend(ForbiddenHintMessage);
            const component = new Ctor(
                Object.assign(
                    {},
                    {
                        propsData: {
                            image: this.processedImage,
                            hints: this.forbiddenHints,
                        },
                    }
                )
            );
            var htmlString = component.$mount().$el;
            return htmlString;
        },
        setPageTitleAndScrollTop() {
            if (!this.showCamera && !this.showCardEditPicture) {
                this.$store.commit(
                    "setTitle",
                    this.$t("person_data_and_picture")
                );
            }
            ScrollUtils.scrollTop();
        },
        upload(file) {
            this.originalImage = file;
            this.showCardEditPicture = true;
            this.editCanGoBackToCamera = false;
        },
    },
    mounted() {
        this.setPageTitleAndScrollTop();
    },
    name: "card-edit",
    props: {
        card: {
            type: Object,
            default: () => null,
        },
    },
    watch: {
        showCamera: "setPageTitleAndScrollTop",
        showCardEditPicture: "setPageTitleAndScrollTop",
        '$store.state.loginFormData': {
            'deep': true,
            handler() {
                this.userInfo = this.$store.state.loginFormData
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    .cloud-card {
        contain: initial;
        overflow: visible;
        &::before {
            content: "";
            position: absolute;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid #123250;
            transform: rotate(0);
            left: -10px;
            right: auto;
            top: calc(50% - 8px);
        }
    }
</style>