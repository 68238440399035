import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VuetifyConfirmBox from 'vuetify-confirm-box';
import VuetifyFloatingMessage from 'vuetify-floating-message';

import themes from './themes';

Vue.use(Vuetify)

import deDeutsch from 'vuetify/lib/locale/de';
import { i18n } from '../localization/i18n';

const vuetify = new Vuetify({
    lang: {
        locales: {deDeutsch},
        current: i18n.locale
    },
    theme: {
        themes
    }
});

Vue.use(VuetifyConfirmBox, { vuetify,
    smartActions: true,
});
Vue.use(VuetifyFloatingMessage, { vuetify });

export default vuetify;
