export const Mask = {
    de: {
        date: {
            mask: '##.##.####',
            placeholder: 'TT.MM.JJJJ'
        }
    },
    en: {
        date: {
            mask: '##/##/####',
            placeholder: 'MM/DD/YYYY'
        }
    }
}