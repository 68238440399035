<template>
    <v-app>
        <v-app-bar class='align-center d-flex justify-center'
                   color="secondary"
                   max-height="64">
            <img :src="require(`@/assets/images/logos/${companyName}.png`)"
                 class="mx-auto my-auto" />
        </v-app-bar>
        <v-main class="secondary">
            <v-container
                         class="text-center white--text my-12 mw-md">
                <h1 class="text-h4">
                    Wartungsarbeiten
                </h1>
                <p class='my-12 text-body-1'>
                    Die #FotoApp
                    steht Ihnen
                    bereits in wenigen
                    Stunden wieder zur
                    Verfügung. Wir wünschen
                    Ihnen frohe Ostern!</p>
                <img class='w-100 mw-xxxs'
                     :src="require('@/assets/images/maintenance.png')" />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
export default {};
</script>