'use strict'

exports._esModule = true;
exports.default = {
    labelButtonCancel: "Abbrechen",
    labelButtonConfirm: "Fertig",
    labelButtonCropFlipHorizontal: "Horizontal spiegeln",
    labelButtonCropFlipVertical: "Vertikal spiegeln",
    labelButtonCropRotateLeft: "Nach links drehen",
    labelButtonCropRotateRight: "Rotate right",
    labelButtonReset: "Zurücksetzen",
    labelStatusAwaitingImage: "Warten auf Bild ...",
    labelStatusLoadImageError: "Fehler beim Laden des Bildes ...",
    labelStatusLoadingImage: "Bild wird geladen ...",
    labelStatusProcessingImage: "Bild verarbeiten ...."
}