import Vue from 'vue';
import axios from 'axios';
import { i18n } from '../localization/i18n';
import VueAxios from 'vue-axios';
import router from '../router/index';
import store from '../store/index';
import EventBus from '../application/EventBus';
import { MessageViewModel } from '../application/ViewModels/MessageViewModel';

var baseApiUrl = process.env.VUE_APP_API_BASE_URL;
var _axios = axios.create({
    baseURL: baseApiUrl,
    headers: {
        'accept-language': i18n.locale,
        'X-Requested-With': 'XMLHttpRequest',
        'Pragma': 'no-cache'
    }
});
_axios.interceptors.request.use(config => {
    store.commit("setLoading", true);
    return config;
}, error => {
    store.commit("setLoading", false);
    console.error(error);
    EventBus.$emit('error', MessageViewModel.error(i18n.t("messages.unknown_error")));
    return Promise.reject(error);
});
_axios.interceptors.response.use(response => {
    if (response.status === 200) {
        store.commit("setLoading", false);
        return response;
    }
}, error => {
    store.commit("setLoading", false);
    var response = error.response;
    if (!response) {
        EventBus.$emit('error', MessageViewModel.error(i18n.t("messages.cannot_reach_server")));
        return Promise.reject(error);
    }
    if (response.status === 404) {
        EventBus.$emit('error', MessageViewModel.error(i18n.t("messages.not_found")));
    }
    if (response.status === 400) {
        if (response.data && response.data !== '') {
            EventBus.$emit('error', MessageViewModel.error(i18n.t(response.data)));
        } else {
            EventBus.$emit('error', MessageViewModel.error(i18n.t("messages.bad_request")));
        }
    }
    if (response.status === 401) {
        if (store.state.userManager) {
            store.state.userManager.signoutRedirect();
        } else {
            router.push('login');
        }
    }
    if (response.status === 500) {
        EventBus.$emit('error', MessageViewModel.error(i18n.t("messages.internal_server_error")));
    }
    return Promise.reject(error);
});

Vue.use(VueAxios, _axios);

export default _axios;
