<template>
    <v-overlay 
        z-index="999"
        :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"></v-progress-circular>
    </v-overlay>
</template>

<script>
export default {
    data() {
        return {
            overlay: true
        }
    },
    name: 'loader'
}
</script>