"use strict"

exports.__esModule = true;
exports.default = {
    "birthdate": "Birthdate",
    "card_number": "Card number",
    "edit_data": "Edit",
    "employee_id_card": "Employee ID Card",
    "firstname": "Firstname",
    "lastname": "Lastname",
    "login": "Login",
    "logout": "Logout",
    "menu": {
        "cards": "Cards",
        "faq": "Q&A",
        "imprint": "Imprint",
        "privacy_policy": "Privacy policy"
    },
    "messages": {
        "consent_confirmation": "I confirm the correction of the information in this page."
    },
    "save": "Save"
}