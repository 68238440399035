<template>
    <nav>
        <v-app-bar app
                   color="secondary"
                   dark
                   dense
                   height="64"
                   style="z-index: 18">
            <v-app-bar-nav-icon @click.native="toggleSidebar"
                                class="hidden-md-and-up top-left-button"></v-app-bar-nav-icon>
            <v-toolbar-title class="mx-auto">
                {{title}}
            </v-toolbar-title>
        </v-app-bar>
    </nav>
</template>

<script>
export default {
    methods: {
        toggleSidebar() {
            this.$emit('toggle-sidebar');
        }
    },
    name: 'menu-bar',
    props: {
        title: {
            type: String,
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
    .v-toolbar {
        left: 0 !important;
    }
    .top-left-button {
        position: absolute;
        top: 0;
        left: 5%;
    }
</style>